<template lang="pug">
v-app
    v-app-bar(
        dense
        app
        elevation="8"
    )
        v-btn(text small :class="!pastHome ? 'active' : ''" v-show="!adjustNav" @click.stop="scrollById('homeAnchor')") Home
        v-btn(text small :class="pastHome && !pastAbout ? 'active' : ''" v-show="!adjustNav" @click.stop="scrollById('aboutAnchor')") About
        v-btn(text small :class="pastHome && pastAbout && !pastTeam ? 'active' : ''" v-show="!adjustNav" @click.stop="scrollById('teamAnchor')") Team
        v-btn(text small :class="pastHome && pastAbout && pastTeam ? 'active' : ''" v-show="!adjustNav" @click.stop="scrollById('contactAnchor')") Contact
        v-spacer
        v-app-bar-nav-icon(@click.stop="mainDrawer = !mainDrawer" v-show="adjustNav")
    v-main
        #homeAnchor.home(:class="$vuetify.theme.isDark ? 'charcoal' : 'white'")
            <!-- The video -->
            <video v-if="!adjustNav" autoplay muted loop id="myVideo">
                <source :src="videoLink" type="video/mp4">
            </video>
            .d-flex.flex-column.align-center.justify-center.fullPage.overlay
                //-.dark-logo-back
                #heroImage.text-center
                .display-1.display-sm-2.mx-auto.text-center.grey--text.mt-14 Instruct. Educate. Cultivate. Refine.
            #aboutAnchor.hacker-green--text.blue-grey.darken-4.raise
                .display-1.px-8.py-4.text-uppercase
                    v-container About Erudio
                v-parallax(:src="require('@/assets/images/dark-logo-back.jpg')" v-bind:height="$vuetify.breakpoint.xs ? 650 : 400")
                    v-row(align-md="center" align-conent-md="center" justify="center")
                        v-col.pa-0(cols="12")
                            v-card.elevation-0.rounded-0.auto-scroll(shaped style="background-color: rgba(0,0,0,0);")
                                div.grey--text.text--lighten-2
                                    v-container
                                        v-row(align-md="center" align-conent-md="center" justify="center")
                                            v-col.pt-10.pt-md-0.pb-0(cols="12" sm="5" lg="6")
                                                .d-flex.align-center.justify-center
                                                    //-img.ma-8.rounded-lg(:src="require('@/assets/images/Cyber-Shield_group.jpg')" style="width: 100%; height:100%")
                                                    v-img.ma-8.float-right(
                                                        :lazy-src="require('@/assets/images/placeholder.png')"
                                                        max-width="479"
                                                        max-height="269"
                                                        alt="Cyber Shield Group Picture"
                                                        :src="require('@/assets/images/Cyber-Shield_group.jpg')"
                                                        style="width:100%;"
                                                    )
                                            v-col.pt-0.px-12.pa-sm-0(cols="12" sm="5" lg="6")
                                                .py-8.px-sm-8
                                                    p.mt-6 Founded by veterans from the United States Military and the Intelligence Community, Erudio Corp provides expertise in the areas of cybersecurity training and exercises, incident response, vulnerability assessment, and many other areas related to the missions of cyber teams. If you've got tough technical or training challenges, our consultants will help you tackle them.
            #teamAnchor.dark-lower-back.raise
                .px-8.pt-4.pb-6.display-1.text-uppercase.hacker-green--text.blue-grey.darken-4
                    v-container Meet our Team
                v-row
                    v-col.pa-0(cols="12")
                        v-card.rounded-0.blue-grey.darken-3
                            div.grey--text
                                v-container
                                    v-row(align-md="center" align-conent-md="center" justify="center")
                                        v-col.pt-10.pt-md-0(cols="12" sm="5" lg="6")
                                            //-img.ma-8.rounded-lg(:src="require('@/assets/images/Cyber-Shield_group.jpg')" style="width: 100%; height:100%")
                                            .d-flex.align-center.justify-center
                                                v-img(
                                                    :lazy-src="require('@/assets/images/placeholder.png')"
                                                    max-width="450"
                                                    max-height="272"
                                                    alt="DJ Presenting Information"
                                                    :src="require('@/assets/images/DJ-Presenting.jpg')"
                                                    style="width:80%;"
                                                )
                                        v-col.px-12.pa-sm-0(cols="12" sm="5" lg="6")
                                            .py-8.px-sm-8
                                                .display-1.grey--text.text--lighten-1 Dr. DJ Hovermale
                                                p.mt-6 Affectionately known as 'Cyber Yoda' or 'The Minister of Cyber Magic', Dr. DJ Hovermale is a widely-recognized expert in military cyber exercises and an award-winning instructor.
                                                p While working for the National Security Agency, Johns Hopkins University Applied Physics Laboratory, and Science Applications International Corporation he was instrumental in the advancement of capstone-level cyber exercises for United States Cyber Command (Cyber Guard and Cyber Flag), the Army National Guard (Cyber Shield), and the United States Army Reserve (WOOLLY KNIT). He has also worked to create customized training solutions for military cyber operators and cyber exercise assessors. He received his undergraduate degree in linguistics from the University of Kentucky in 2004 and received his Ph.D. in computational linguistics from Ohio State in 2011. He is a graduate of the Joint Advanced Cyber Warfare Course (JACWC) and the Harvey Brightman Master Teacher Program.
                        v-card.rounded-0.blue-grey.darken-4
                            div.grey--text
                                v-container
                                    v-row(align-md="center" align-conent-md="center" justify="center")
                                        v-col.px-12.pa-sm-0(cols="12" sm="5" lg="6")
                                            .py-8.px-sm-10
                                                p DJ has received countless awards for his teaching and for his contributions to the military cyber community, including the Order of Thor from the Military Cyber Professionals Association.
                                                p He is dedicated to giving back to the community whenever and however he can, serving as a scoutmaster and district commissioner for the Boy Scouts of America, coaching youth sports teams, and mentoring cyber teams at the middle school, high school, and collegiate levels. He has a passion for education that is very difficult to match and he has mentored hundreds of young cyber professionals from the military, government, and academic institutions over the past 10 years.
                                                p DJ lives in Huntsville, AL with his wife and four children where he also serves as volunteer clergy in his church and teaches cybersecurity courses at a local university.
                                        v-col.pb-15.pb-md-0.mt-0.mt-sm-10.mt-md-0(cols="12" sm="5" lg="6")
                                            //-img.ma-8.rounded-lg(:src="require('@/assets/images/Cyber-Shield_group.jpg')" style="width: 100%; height:100%")
                                            .d-flex.align-center.justify-center
                                                //-img.ma-8.rounded-lg(:src="require('@/assets/images/Cyber-Shield_group.jpg')" style="width: 100%; height:100%")
                                                v-img(
                                                    :lazy-src="require('@/assets/images/placeholder.png')"
                                                    max-width="450"
                                                    max-height="272"
                                                    alt="DJ working his Cyber Magic"
                                                    :src="require('@/assets/images/DJ-Working-Cyber-Magic.jpg')"
                                                    style="width:80%;"
                                                )
                v-row
                    v-col.pa-0(cols="12")
                        v-card.rounded-0.blue-grey.darken-3
                            div.grey--text
                                v-container
                                    v-row(align-md="center" align-conent-md="center" justify="center")
                                        v-col.pt-10.pt-md-0(cols="12" sm="5" lg="6")
                                            .d-flex.align-center.justify-center
                                                v-img.flex-grow-0(
                                                    :lazy-src="require('@/assets/images/placeholder.png')"
                                                    width="333"
                                                    height="500"
                                                    alt="Image of Stan Lozovsky"
                                                    :src="require('@/assets/images/Stan-Lozovsky.jpg')"
                                                )
                                        v-col.px-12.pa-sm-0(cols="12" sm="5" lg="6")
                                            .py-8.px-sm-8
                                                .display-1.grey--text.text--lighten-1 Stan Lozovsky
                                                p.mt-6 Stan Lozovsky has served over twenty years in the Alabama National Guard. Commissioned in 2002 as an Infantry Officer and branch detailed to Field Artillery, he is currently serving as the Commander of the 175th Cyber Protection Team for the Alabama National Guard. He has served in Iraq and Afghanistan in support OIF and OEF where he received a Bronze Star for his service. He worked as a systems engineer at SAIC prior to founding H2L Solutions, Inc. in 2014 which is a Inc. 5000 company. He has served in a variety of technical and non-technical positions in his civilian and military career supporting the Army and the Missile Defense Agency.
                                                p Stan is involved with mentoring the New Century High School Cyber Patriot Team and is currently serving as a director for the North Alabama Information Systems Audit and Control Association (ISACA) chapter as the past chapter president from 2017 through 2021.  He has earned two bachelor’s degrees from The University of Alabama in Finance and in Russian, as well as a Masters in Cybersecurity from the University of Alabama in Huntsville. He holds several certifications from CompTIA, ISACA, and ISA and has been married to his wife Tiffany Lozovsky for over fifteen years.
                        //- v-card.rounded-0.blue-grey.darken-4
                        //-     div.grey--text
                        //-         v-container
                        //-             v-row(align="center" align-conent="center" justify="center")
                        //-                 v-col.pa-0(cols="6")
                        //-                     .py-8.px-sm-10
                        //-                         p Stan is involved with mentoring the New Century High School Cyber Patriot Team and is currently serving as a director for the North Alabama Information Systems Audit and Control Association (ISACA) chapter as the past chapter president from 2017 through 2021.  He has earned two bachelor’s degrees from The University of Alabama in Finance and in Russian, as well as a Masters in Cybersecurity from the University of Alabama in Huntsville. He holds several certifications from CompTIA, ISACA, and ISA and has been married to his wife Tiffany Lozovsky for over fifteen years.
                        //-                 v-col.pa-0
                        //-                     //-img.ma-8.rounded-lg(:src="require('@/assets/images/Cyber-Shield_group.jpg')" style="width: 100%; height:100%")
                        //-                     v-img.my-md-12.float-right.mr-13(
                        //-                         :lazy-src="require('@/assets/images/DJ-Working-Cyber-Magic.jpg')"
                        //-                         max-width="450"
                        //-                         max-height="272"
                        //-                         :src="require('@/assets/images/DJ-Working-Cyber-Magic.jpg')"
                        //-                     )
            #contactAnchor.raise
                .px-8.pt-4.pb-3.display-1.text-uppercase.hacker-green--text.blue-grey.darken-4
                    v-container Contact Us
                .dark-lower-back(style="height:100vh")
                    .d-flex.align-center.justify-space-around.flex-wrap(style="height:60vh")
                        v-card.rounded-0.mx-4(max-width="350px")
                            v-card-title.blue-grey.darken-4
                                v-avatar
                                    v-img(:src="require('@/assets/images/DJ-Head_sm.jpg')" alt="Picture of DJ")
                                .hacker-green--text.pl-4.display-1
                                    span DJ Hovermale
                                    .hacker-green--text.text-h6 Chief Visionary
                            v-list.pb-1
                                v-list-item
                                    v-list-item-action
                                        v-icon mdi-email
                                    v-list-item-content
                                        a.hacker-green--text(:href="getUnHash('theDJ')")
                                            span(v-html="dTheThis")
                                            span(v-html="dTheThen")
                                            span(v-html="dTheThat")
                                            span(v-html="dAndThis")
                            //- v-img(:src="require('@/assets/images/DJ-Presenting.jpg')" alt="DJ Presenting" height="100px")
                        v-card.rounded-0.mx-4(max-width="350px")
                            v-card-title.blue-grey.darken-4
                                v-avatar
                                    v-img(:src="require('@/assets/images/Stan-Head_sm.jpg')" alt="Picture of Stan")
                                .hacker-green--text.pl-4.display-1
                                    span Stan Lozovsky
                                    .hacker-green--text.text-h6 Executive Director
                            v-list.pb-1
                                v-list-item
                                    v-list-item-action
                                        v-icon mdi-email
                                    v-list-item-content
                                        a.hacker-green--text(:href="getUnHash('theStan')")
                                            span(v-html="sTheThis")
                                            span(v-html="sTheThen")
                                            span(v-html="sTheThat")
                                            span(v-html="sAndThis")
    v-navigation-drawer#navigationDrawer(
        v-model="mainDrawer"
        fixed
        temporary
        right
    )
        v-list(dense right)
            v-list-item(
                v-for="item in items"
                :key="item.title"
                link
                @click.stop="scrollById(item.linkId)"
            )
                v-list-item-icon
                    v-icon(v-text="item.icon")
                v-list-item-content
                    v-list-item-title(v-text="item.title")
    v-footer.elevation-8(padless)
        v-card(class="white--text" width="100%")
            v-card-text
                v-row(align="center" justify="center")
                    v-spacer
                    v-col.text-center(cols="12" md="4")
                        a.social-link(v-for="footerLink in footerLinks" :key="footerLink.icon" :href="footerLink.link" class="mx-4 white--text")
                            v-icon.hacker-green--text(size="24px" v-text="footerLink.icon")
                    v-col.text-right
                        span &copy;
                        span(v-text="new Date().getFullYear()") 
                        span  - 
                        strong Erudio
</template>

<script>
    import Footer from "@/components/Footer";

    // Import the functions you need from the SDKs you need
    import { initializeApp } from "firebase/app";
    import { getAnalytics } from "firebase/analytics";
    // TODO: Add SDKs for Firebase products that you want to use
    // https://firebase.google.com/docs/web/setup#available-libraries

    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
        apiKey: "AIzaSyCv5HNXPeQgTgOhqLvy7pVe2LvIcO6LD34",
        authDomain: "erudio-ddd23.firebaseapp.com",
        projectId: "erudio-ddd23",
        storageBucket: "erudio-ddd23.appspot.com",
        messagingSenderId: "904173922719",
        appId: "1:904173922719:web:c0b3478bd197f63b7f75aa",
        measurementId: "G-1SF3MQBCXT"
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);

    export default {
        name: "App",
        components: {
            footer: Footer,
        },
        data: () => ({
            items: [
            { title: "Home", icon: "mdi-home", linkId: "homeAnchor" },
            { title: "About", icon: "mdi-information", linkId: "aboutAnchor" },
            { title: "Team", icon: "mdi-account-group", linkId: "teamAnchor" },
            { title: "Contact", icon: "mdi-card-account-mail-outline", linkId: "contactAnchor"}
            ],
            icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
            footerLinks: [
                {
                    name: 'Facebook',
                    link: 'https://facebook.com/ErudioCorp',
                    icon: 'mdi-facebook'
                },
                {
                    name: 'Twitter',
                    link: 'https://twitter.com/erudiocorp',
                    icon: 'mdi-twitter'
                },
                {
                    name: 'Instagram',
                    link: 'https://www.instagram.com/erudiocorp/',
                    icon: 'mdi-instagram'
                },
                {
                    name: 'LinkedIn',
                    link: 'https://www.linkedin.com/company/erudio-corp/',
                    icon: 'mdi-linkedin'
                },
            ],
            dTheThis: 'dj',
            dTheThen: '&#64;',
            dTheThat: 'erudiocyber',
            dAndThis: '&#46;com',
            sTheThis: 'stan',
            sTheThen: '&#64;',
            sTheThat: 'erudiocyber',
            sAndThis: '&#46;com',
            mainDrawer: false,
            adjustNav: false,
            pastHome: false,
            pastAbout: false,
            pastTeam: false,
            isActive: false,
            isActive2: false,
            hideScrolldownArrow: false,
        }),
        computed: {
            videoLink() {
                let videoLink = '';
                if(window.innerWidth >= 500)
                    videoLink = require('@/assets/video/BinaryCode_720_m.mp4');
                return videoLink;
            }
        },
        mounted() {
            this.onResize();
            this.handleScroll();
            window.addEventListener("resize", this.onResize, { passive: true });
            window.addEventListener("scroll", this.handleScroll);
            document.title = "Erudio";
            this.$vuetify.theme.dark = true;
            //console.log('document.getElementById(aboutAnchor).offsetTop', document.getElementById('aboutAnchor'));
        },
        beforeDestroy() {
            if (typeof window === "undefined") return;
            window.removeEventListener("resize", this.onResize, { passive: true });
            window.removeEventListener("scroll", this.handleScroll);
        },
        methods: {
            onResize() {
                this.adjustNav = window.innerWidth < 500;
            },
            handleScroll() {
                if (window.scrollY > 1) {
                    this.hideScrolldownArrow = true;
                } else {
                    this.hideScrolldownArrow = false;
                }
                // console.log('window.scrollY', window.scrollY);
                if (window.scrollY > window.visualViewport.height) {
                    this.pastHome = true;
                } else {
                    this.pastHome = false;
                }
                if(document.getElementById("aboutAnchor")) {
                    let aboutAnchorObj = document.getElementById("aboutAnchor");
                    if (
                        window.scrollY >
                        aboutAnchorObj.offsetTop +
                        aboutAnchorObj.offsetHeight -
                        (aboutAnchorObj.offsetHeight / 2 + 70)
                    ) {
                        this.pastAbout = true;
                    } else {
                        this.pastAbout = false;
                    }
                }
                if(document.getElementById("teamAnchor")) {
                    let teamAnchorObj = document.getElementById("teamAnchor");
                    if (
                        window.scrollY >
                        teamAnchorObj.offsetTop +
                        teamAnchorObj.offsetHeight -
                        (400)
                    ) {
                        this.pastTeam = true;
                    } else {
                        this.pastTeam = false;
                    }
                }
                // if (window.scrollY <= 100) {
                //     if (!this.defaultImage) {
                //         return (this.isDefaultImage = true);
                //     }
                // }
            },
            scrollById(id) {
                let element = document.getElementById(id);
                let topOffset = element.offsetTop;
                this.mainDrawer = false;
                window.scrollTo({
                    top: topOffset,
                    behavior: "smooth",
                });
            },
            decodeHtml(html) {
                var txt = document.createElement("textarea");
                txt.innerHTML = html;
                return txt.value;
            },
            getUnHash(v) {
                if(v === 'theDJ')
                    return this.decodeHtml('ma' + 'ilto:' + this.dTheThis + this.dTheThen + this.dTheThat + this.dAndThis);

                if(v === 'theStan')
                    return this.decodeHtml('ma' + 'ilto:' + this.sTheThis + this.sTheThen + this.sTheThat + this.sAndThis);
                
                return '';
            }
        },
    };
</script>

<style lang="scss">
    $hacker-green: #20c20e;

    body {
        overflow-x: hidden;
    }

    button {
        border: 2px solid transparent;

        &.active {
            font-weight: bold;
            border: 2px solid $hacker-green !important;
            color: $hacker-green !important;
        }
    }

    .hacker-green {
        background-color: $hacker-green !important;

        &--text {
            color: $hacker-green !important;
        }
    }

    .fullPage {
        position: relative;
        min-height: calc(100vh - 0px);
    }

    .halfPage {
        position: relative;
        min-height: calc(50vh - 0px);
    }

    .charcoal {
        background-color: #272727 !important;
    }

    #heroImage {
        width: 90vw;
        height: 340px;
        background-image: url("~@/assets/images/erudio-temp4-background.png");
        background-position: top center;
        background-size: contain;
    }

    .sloganFont {
        // font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-family: Garamond !important;
        font-weight: bold;
        font-size: 3em;
    }

    .dark-logo-back {
        background-image: url("~@/assets/images/dark-logo-back.jpg");
        background-position: top center;
        background-size: cover;
    }

    .dark-lower-back {
        background-image: url("~@/assets/images/dark-lower-back.jpg");
        background-position: top center;
        background-size: cover;
    }

    .background-fade {
        background-color: #053238;
        background-image: url("~@/assets/images/net-fade.png");
        background-position: bottom right;
        background-size: cover;
    }

    .green-beaker {
        background-image: url("~@/assets/images/binary-code.jpg");
        background-position: bottom right;
        background-size: cover;
    }

    .green-brain {
        background-image: url("~@/assets/images/green-brain.jpg");
        background-position: center right;
        background-size: cover;
    }

    .hacker-back {
        background-image: url("~@/assets/images/hacker-fade.jpg");
        background-position: center center;
        background-size: cover;
    }

    .green-gradient {
        background: rgb(0, 255, 81);
        background: linear-gradient(
            90deg,
            rgba(0, 255, 81, 1) 0%,
            rgba(32, 194, 14, 1) 47%,
            rgba(111, 255, 62, 1) 100%
        );
    }

    .transparent-green {
        background-color: rgba(0, 255, 81, 0.3);
    }

    .scrolldown-arrow {
        position: absolute;
        bottom: 48px;
        width: 100vw;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.3);
        transition: all 0.25s ease-in;
    }
    // .matalic-gold {
    //     color: #dfaf37;
    // }

    .social-link {
        text-decoration: none;
    }

    .bigSection {
        height: 300px;
    }

    .auto-scroll {
        overflow: auto;
        max-height: 350px;

        @media screen and (max-width: 600px) {
            max-height: unset !important;
        }
    }

    .raise {
        position: relative;
    }

    .overlay {
        background-color: rgba(0, 00, 0, 0.9);
    }

    #myVideo {
        position: fixed;
        right: 0;
        bottom: 0;
        min-width: 100%;
        min-height: 100%;
        z-index: 0;
    }
</style>
